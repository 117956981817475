const dataForServicesPage = [
  {
    id: "10",
    title: "Software Development",
    image: "SoftwareDevelopment",
    link: "/services/web/",
  },
  {
    id: "20",
    title: "Mobile App Development",
    image: "MobileDevelopment",
    link: "/services/mobile/",
  },
  {
    id: "30",
    title: "Startup Consultancy",
    image: "Startup",
    link: "https://startup.os-system.com/",
  },
  {
    id: "40",
    title: "Quality Assurance",
    image: "Quality",
    link: "/services/qa/",
  },
  {
    id: "50",
    title: "UI/UX Design",
    image: "Design",
    link: "/services/uiuxdesign/",
  },
  {
    id: "60",
    title: "Dedicated Team",
    image: "DedicatedTeam",
    imageId: "",
    link: "/services/dedicatedteam/",
  },
]

export default dataForServicesPage

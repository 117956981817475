import React from "react"

import ServiceItem from "../ServiceItem"

import Plus from "../../../images/ServicesPage/ServicesList/Icons/plus.svg"

import { Link } from "gatsby"

import "./ServicesList.scss"
import "./IconAnimations.scss"

const ServicesList = ({ services }) => {
  return (
    <React.Fragment>
      <div className="row services-list">
        {services.map(service => (
          <ServiceItem key={service.id} {...service} />
        ))}
      </div>
      <div className="estimate-btn-container">
        <Link to="/estimate/" className="btn">
          <span>
            New estimate
            <img src={Plus} />
          </span>
        </Link>
      </div>
    </React.Fragment>
  )
}

export default ServicesList

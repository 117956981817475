import React from "react"
import SEO from "../components/CommonComponents/SEO"
import Layout from "../components/CommonComponents/Layout"
import ServiceHeader from "../components/Services/ServiceHeader"
import ServicesList from "../components/Services/ServiceList"
import WorkTogetherSection from "../components/Services/WorkTogetherSection"
import dataForServicePage from "../information/ServicePage/dataForServicePage"
import ServicesBG from "../images/ServicesPage/ServiceHeader/main_photo.jpg"
import { mainUrl } from "../js/config"

export default function Services() {
  return (
    <section>
      <SEO
        title="Applicaton Development Services "
        description="Our team will help you with web and mobile development for your business needs. 13+ years of experience."
        canonical={`${mainUrl}/services/`}
      />
      <Layout showFormPoint={200}>
        <ServiceHeader title="Services" BG={ServicesBG} />
        <div className="container">
          <div className="services-wrapper">
            <ServicesList services={dataForServicePage} />
          </div>
        </div>
        <WorkTogetherSection />
      </Layout>
    </section>
  )
}

import React from "react"
import GatsbyLink from "/src/components/CommonComponents/GatsbyLink"

import {
  DedicatedTeam,
  MobileDevelopment,
  Quality,
  SoftwareDevelopment,
  Startup,
  Design,
} from "../../../images/ServicesPage/ServicesList"

const ServiceItem = ({ title, image, link }) => {
  let Icon = null

  switch (image) {
    case "SoftwareDevelopment":
      Icon = <img src={SoftwareDevelopment} alt='Service Icon' />
      break
    case "MobileDevelopment":
      Icon = <img src={MobileDevelopment} alt='Service Icon' />
      break
    case "Startup":
      Icon = <img src={Startup} alt='Service Icon' />
      break
    case "Quality":
      Icon = <img src={Quality} alt='Service Icon' />
      break
    case "Design":
      Icon = <img src={Design} alt='Service Icon' />
      break
    case "DedicatedTeam":
      Icon = <img src={DedicatedTeam} alt='Service Icon' />
      break
    default:
      Icon
      break
  }

  return (
    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 services-list_item">
      <GatsbyLink to={link}>
        {Icon}
        <p>{title}</p>
      </GatsbyLink>
    </div>
  )
}

export default ServiceItem
